<template>
  <div receiver-signicat-idin>
    <ProgressTitle :default-label="'title.idinComplete'" :is-final="true" />
    <p v-html="$t('_.description', {brand: brandName})" />
    <ProgressButton button-name="continue" @click="callScheme" />
  </div>
</template>

<script>
import { state } from '@shared/utils/storeUtils.mjs';
import ColorButton from '@shared/components/common/ColorButton.vue';
import baseTemplate from '@/mixins/baseTemplate';
import ProgressButton from '@/views/components/common/ProgressButton.vue';
import ProgressTitle from '@/views/components/pages/on-boarding/ProgressTitle.vue';
export default {
  name: 'ReceiverSignicatIdin',
  lexicon: 'onBoarding.signicat.idinComplete',
  components: { ProgressTitle, ProgressButton },
  mixins: [baseTemplate],
  computed: {
    brandName: state('env', 'brandName'),
  },
  data() {
    return {
      appVersion: null,
      userAgent: null,
      vendor: null,
      platform: null,
    };
  },
  methods: {
    callScheme() {
      location.href = 'ggpoker://action';
    }
  },
  mounted() {
    this.$nextTick(() => this.callScheme());
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[receiver-signicat-idin] {
  > p { .fs(16); .c(@c-w07); }
  [progress-button] { .mt(55); }
}
</style>